<template>
    <div>
        <div class="main-title">客户分配规则</div>
        <div class="content-list-page page-content">


            <div class="padding-t-sm padding-b-sm">
                <span class="fl-l">
                    <el-input v-model="searchData.counselor_id" size="mini" placeholder="咨询师ID"></el-input>
                </span>

                <span class="margin-l-sm fl-l">
                    <el-input v-model="searchData.counselor_name" size="mini" placeholder="咨询师昵称"></el-input>
                </span>


                <span class="margin-l-sm fl-l">
                    <el-button type="primary" icon="el-icon-search" size="small" @click="search">搜索</el-button>
                </span>

                <span class="fl-r">
                    <router-link to="/ruleDistribution/add" class="router-link">
                        <el-button type="primary" size="small">新增</el-button>
                    </router-link>
                </span>

                <div style="clear: both"></div>
            </div>

            <div class="table-content">
                <el-table :header-cell-style="{ padding: 0 }" :cell-class-name="rowItem" ref="singleTable" :data="tableData"
                    v-loading="loading" style="width: 100%; background: #fff">
                    <el-table-column label="ID" property="id" width="80">
                    </el-table-column>


                    <el-table-column property="everyday_distribution" label="每天分配数量">
                    </el-table-column>
                    <el-table-column property="today_distribution" label="今日分配数量">
                    </el-table-column>
                    <el-table-column property="total_distribution" label="累计分配数量">
                    </el-table-column>
              

                    <el-table-column property="counselor_name" label="咨询师">
                    </el-table-column>

                    <el-table-column property="creator_name" label="创建人">
                    </el-table-column>
                    <el-table-column property="created_at" label="创建时间">
                    </el-table-column>
                    <el-table-column property="updated_at" label="更新时间">
                    </el-table-column>



                    <el-table-column property="address" label="操作">
                        <template slot-scope="scope">
                            <span><el-link slot="reference" type="primary"
                                    @click="$router.push('/ruleDistribution/edit/' + scope.row.id)">编辑</el-link></span>
                            -

                            <span>
                                <el-popconfirm icon="el-icon-info" icon-color="red" title="删除后将不可恢复，确定删除吗？"
                                    @confirm="deleted(scope.row)">
                                    <el-link slot="reference" type="primary">删除</el-link>
                                </el-popconfirm>
                            </span>
                        </template>
                    </el-table-column>
                </el-table>
            </div>

            <div class="table-batch">
                <span class="fl-r">
                    <el-pagination small background :current-page="page" :page-sizes="[10, 20]" :page-size="pageSize"
                        layout="total, sizes, prev, pager, next, jumper" @size-change="setPageSize"
                        @current-change="setPage" :total="total">
                    </el-pagination>
                </span>
                <div style="clear: both"></div>
            </div>
        </div>
    </div>
</template>

<script>
import { mapActions } from "vuex";

export default {
    name: "list",
    data() {
        return {
            page: 1,
            pageSize: 10,
            total: 0,
            loading: true,
            imageUrl: config.imageUrl,
            searchData: {},
            tableData: [],
        };
    },
    methods: {
        ...mapActions("ruleDistribution", ["listCustomerDistributionRule", "delCustomerDistributionRule"]),



        rowItem() {
            return "row-item";
        },
        search() {
            this.tableData = [];
            this.page = 1;
            this.getList();
        },
        async deleted(row) {
            const { res_info } = await this.delCustomerDistributionRule({ id: row.id });
            if (res_info != "ok") return;
            await this.getList()
            this.$message.success("删除成功");
        },


        async getList() {
            this.loading = true;
            let form = {
                ...this.searchData,
                page: this.page,
                pageSize: this.pageSize
            }
            const { data } = await this.listCustomerDistributionRule(form)
            this.tableData = data.list
            this.total = data.total
            this.loading = false;
        },
        setPageSize(pageSize) {
            this.pageSize = pageSize;
            this.getList();
        },
        setPage(page) {
            this.page = page;
            this.getList();
        },
    },
    mounted() {
        this.getList();
    },
};
</script>

<style scoped></style>
